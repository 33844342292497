import { React } from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from './Header';
import Footer from './Footer';
import DownTime from './DownTime';
import cookie from 'react-cookies';
// import { useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import FinalizeAccount from './FinalizeAccount';
import PublisherList from './PublisherList';
import Profile from './Profile';
import EditProfile from './EditProfile';
import Account from './Account';
import Tos from './Tos';
import AuthRouter from './AuthRouter';

const axios = require('axios');

window['_fs_ready'] = () => {
  axios.defaults.params = axios.defaults.params || {};
  axios.defaults.params.fsSessionUrl = window.FS.getCurrentSessionURL();
  let fsSessionId = cookie.load('fsSessionId');
  if(!fsSessionId) {
    fsSessionId = window.FS.getCurrentSession();
    cookie.save('fsSessionId', fsSessionId, {
      path: '/'
    });
  }
};

const App = () => {
    return (
        <Router>
          <Header />
          <Container id="container">
              <Routes>
                <Route path="/" exact element={<AuthRouter />} />
                <Route path="/auth/callback" exact element={<AuthRouter />} />
                <Route path="/account" exact element={<Account />} />
                <Route path="/account/publishers" exact element={<PublisherList />} />
                <Route path="/account/finalize" exact element={<FinalizeAccount />} />
                <Route path="/profile" exact element={<Profile />} />
                <Route path="/profile/edit" exact element={<EditProfile />} />
                <Route path="/tos" exact element={<Tos />} />
                <Route path="/downtime" exact element={<DownTime />} />
              </Routes>
          </Container>
          <Footer />
        </Router>
    );
}

export default App;
